<section class="container-add">
  <article class="content-add-user">
    <div class="closing-btn">
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="title-add-user">Edit User Profile</div>

    <form
      [formGroup]="editUserForm"
      (ngSubmit)="editUser()"
      class="form-add-new-user"
    >
      <!-- <h3 class="role">{{role}}</h3> -->
      <div class="container-row-only">
        <span class="account-info"> Account Info</span>
      </div>
      <div class="container-row-only">
        <h3 class="username bold">Email</h3>
        <h3 class="email">{{ dataDaiolog.email }}</h3>
      </div>
      <div class="container-row-only mb20px">
        <h3 class="username bold">Id</h3>
        <h3 class="user">{{ dataDaiolog.id }}</h3>
      </div>
      <div class="container-row-only mb20px">
        <span class="account-info">Personal Info</span>
      </div>
      <div class="inputs">
        <mat-form-field class="first" appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            formControlName="firstName"
            matInput
            placeholder="First Name"
            value="{{ this.authService.given_name }}"
          />
        </mat-form-field>
        <mat-form-field class="first" appearance="outline">
          <mat-label>Middle Name</mat-label>
          <input
            formControlName="middleName"
            matInput
            placeholder="Middle Name"
            value="{{ this.authService.middle_name }}"
          />
        </mat-form-field>
        <mat-form-field class="last" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            formControlName="lastName"
            matInput
            placeholder="Last Name"
            value="{{ this.authService.family_name }}"
          />
          <!-- <mat-hint>Plese add family name</mat-hint> -->
        </mat-form-field>
      </div>
      <div
        [ngClass]="
          this.authService.permissions?.includes('update:organization_users')
            ? 'display-block'
            : 'display-none'
        "
      >
        <mat-form-field appearance="outline" class="w100">
          <mat-label>{{ role }}</mat-label>
          <mat-select
            formControlName="role"
            [disabled]="
              this.authService.permissions?.includes('update:platform_users') ||
              false
            "
          >
            <mat-option *ngFor="let role of roles" [value]="role.value">
              {{ role.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button-wrapper gap15 mt10px">
        <div class="btn-start">
          <button
            mat-flat-button
            class="text-white delete-btn"
            type="button"
            (click)="
              openReactivateAccountDialog(
                dataDaiolog.enabled,
                dataDaiolog.email,
                dataDaiolog.id
              )
            "
          >
            Recover Account
          </button>
        </div>
        <div class="rightBtn">
          <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
          <button
            mat-flat-button
            color="warn"
            class="text-white"
            type="submit"
            [disabled]="!editUserForm.valid"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </article>
</section>
