import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop"
import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"

import { CommonService } from "../.../../../../../../core/services/commonservice.service"
import { WorkerserviceService } from "../../../../../core/services/workerservice.service"
import { AuthService } from "src/app/core/services/auth.service"
export interface ISampleSet {
  name: string
}

export interface PeriodicElement {
  description: string
  inputsAttributes: string
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    description:
      "is the collection of samples in a study for which the researcher desires to the have concentration imputed together and  collated in a single “Concentration Table” ",
    inputsAttributes: "Sample Set Name",
  },
  {
    description:
      "is the identification for each individual sample (i.e., MS injection) in the Sample Set",
    inputsAttributes: "Sample Name",
  },
  {
    description:
      "a link specifying the location of the raw file for each Sample Name in the Sample Set (e.g. path in S3 bucket)",
    inputsAttributes: "Raw File Path",
  },
]

export interface OutputElement {
  description: string
  inputsAttributes: string
}
const outputInfo: OutputElement[] = [
  {
    description:
      "The output file consisting of a table with a row for each metabolite in the Pyxis menu and a column for each Sample Name in the Sample Set. For each metabolite detected in the raw file, the absolute concentration (in uM) will be in the cell.",
    inputsAttributes: "Concentration Table",
  },
]
@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.scss"],
})
export class ProgramComponent implements OnInit {
  displayedColumnsIntup: string[] = ["inputsAttributes", "description"]
  dataSourceInput = ELEMENT_DATA

  displayedColumnsOutput: string[] = ["inputsAttributes", "description"]
  dataSourceOutput = outputInfo

  programContainer: any[] = []
  id: any = ""
  currentProgram: any
  sampleSetID: any
  showSide: boolean = false

  constructor(
    private workerService: WorkerserviceService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public http: HttpClient,
    private router: Router,
    public authService: AuthService,
  ) {
    //this.sampleSetID = this.router.getCurrentNavigation()?.extras.state
    this.sampleSetID = this.commonService.sampleSetId.value
  }

  listProgramsUrl() {
    let base = "/list_programs/"
    if (this.authService.permissions?.includes("read:platform_programs")) {
      base += "platform/"
    }
    return base
  }

  ngOnInit(): void {
    this.http.get(this.listProgramsUrl()).subscribe((response: any) => {
      this.programContainer = this.getGroups(response)
    })
  }

  navigateToJob(program: any) {
    this.router.navigateByUrl(
      `program-parameters/${this.id}`,
      // @ts-ignore
      {
        state: program,
      },
    )
  }

  hoverSide() {
    this.showSide = !this.showSide
  }

  getGroups(data: any) {
    const groups = data.reduce((groups: any, program: any) => {
      const prog_name = program["program_name"]
      if (!groups[prog_name]) {
        groups[prog_name] = []
      }
      //program.id = program["id"];
      //program.versions.append(program["program_version"]);
      groups[prog_name].push(program)
      return groups
    }, {})

    const groupArrays = Object.keys(groups).map((name) => {
      return {
        name,
        programs: groups[name].sort((a: any, b: any) => {
          return a.program_version.localeCompare(b.program_version)
        }),
      }
    })

    return groupArrays
  }
}
