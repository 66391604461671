<section class="bg-sample pt30px">
  <div class="content container-row-response">
    <div class="left-container">
      <article class="container-row head">
        <div class="w80">
          <span class="title-set">Programs</span><br />
          <!-- <span>General Info</span> -->
        </div>
      </article>

      <ng-container *ngFor="let program of programContainer">
        <article
          (mouseover)="currentProgram = program.id"
          (mouseout)="(currentProgram != program.id)"
          class="container-column mine-article card-half mt50px"
        >
          <div class="container-row">
            <div class="container-flex resp-w">
              <span class="visualization">Model</span>
              <span class="title-set-card">
                {{ program.name }}
              </span>
              <div class="text-vers">Versions:</div>
              <div class="container-row">
                <div
                  class="center-center gap8"
                  *ngFor="let version of program.programs"
                >
                  <div class="chips">
                    <span class="chips-span chips-inside">{{
                      version.program_version.replace("v", "")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- 
                            <div class="center-center gap8" *ngFor="let version of program.programs">
                                <div class="chips">
                                    <span class="chips-span chips-inside"> V {{version.program_version}}</span>
                                </div>

                            </div> -->
            </div>
            <div class="btn-end">
              <button
                *ngIf="currentProgram === program.id"
                type="button"
                mat-button
                class="accent-hover"
                (click)="navigateToJob(program)"
              >
                Configure Job
              </button>
            </div>
          </div>
          <div class="center-center">
            <span class="text">
              The {{ program.program_name }} program applies the Pyxis algorithm
              for imputing absolute concentration from data acquired in
              accordance with the Pyxis candles method. The program will read
              all raw files in the sample set, and for all metabolites in the
              specified concentration range of the Pyxis menu provide both an
              absolute concentration and a prediction error.
            </span>
          </div>
        </article>
      </ng-container>
    </div>
    <!-- <div class="rightCol sideCol">
            <article class="container-column">
                <div class="card-basic position-fix-response">
                    <span class="title-right">Program Attributes</span>
                    <div>
                        <table mat-table [dataSource]="dataSourceInput" class="table-right">
                            <ng-container matColumnDef="inputsAttributes">
                                <th mat-header-cell *matHeaderCellDef>Inputs Attributes</th>
                                <td mat-cell *matCellDef="let element">{{element.inputsAttributes}} </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef> Description </th>
                                <td mat-cell *matCellDef="let element"><span class="description-td">
                                        {{element.description}}</span> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsIntup"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsIntup;"
                                style="background-color: transparent !important;"></tr>
                        </table>
                    </div>
                    <div class="mt30px">
                   
                        <table mat-table [dataSource]="dataSourceOutput" class="table-right">
                            <ng-container matColumnDef="inputsAttributes">
                                <th mat-header-cell *matHeaderCellDef>Outputs Attributes</th>
                                <td mat-cell *matCellDef="let element">{{element.inputsAttributes}} </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef> Description </th>
                                <td mat-cell *matCellDef="let element"><span class="description-td">
                                        {{element.description}}</span> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsIntup"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsIntup;"></tr>
                        </table>
                    </div>
                </div>
            </article>
        </div> -->
  </div>
</section>
