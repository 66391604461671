
export const environment = {
   production: true,
   baseUrl: 'https://dev.matterworks.ai/api',
   cognito: {
    userPoolId: "us-east-1_Z4AaqBRE5",
    userPoolWebClientId: "358r329tpph0d3fe1fst7ed91r",
    region: "us-east-1",
  identityPoolId: "",
  },
  auth_domain: "dev.matterworks.ai",
  auth_client_id: "wjvFneejP49x88bj7A5s34YhYSLEgjv7",
  auth_audience: "matterworks.ai",
  auth_issuer: "https://dev-dmxte2bgqavqeo5m.us.auth0.com/",
  ddbTableName: 'LoginTrail',
  timeout: "3600",
  cognito_idp_endpoint: "",
  cognito_identity_endpoint:"",
  sts_endpoint: "",
  dynamodb_endpoint:"",
  s3_endpoint: "",
  users_guide: "https://matterworks.gitbook.io/pyxis-user-guide/HP5L1INVgeKGRRKglGqU/web-application/running-the-web-application",
  version: "staging-develop-cb9bc2354bc121345eaf055329b28f91d2798530",
  revision_date: "September 13, 2024",
  gitbook_privatekey: "e0975cf3-f1d3-4ae4-ba5f-9123544952c3"
};
