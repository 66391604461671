import { ChangeDetectorRef, Component, OnInit } from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CustomSnackbarUserComponent } from "../custom-snackbar-user/custom-snackbar-user.component"
import { MatDialog } from "@angular/material/dialog"
import { HttpClient } from "@angular/common/http"
import { catchError } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { LocalStorageService } from "ngx-localstorage"

@Component({
  selector: "app-add-new-user-dialog",
  templateUrl: "./add-new-user-dialog.component.html",
  styleUrls: ["./add-new-user-dialog.component.scss"],
})
export class AddNewUserDialogComponent implements OnInit {
  addNewUserForm!: FormGroup
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  role: any
  isReadonly = true
  organization: any
  selectCtrl = new FormControl()
  searchCtrl = new FormControl()
  options: any
  filteredOptions: any
  defaultGroupValue: string | undefined
  currentOrg: string | undefined
  isLoading: boolean = false
  rolesList: any[] = [
    // 'User', 'Admin', 'Super Admin'
    "0",
    "1",
    "2",
  ]

  constructor(
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    private dialog: MatDialog,
    public http: HttpClient,
    public authService: AuthService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {
    this.isLoading = false
    this.commonService.userLoading = false
    this.options = this.commonService.groups
    this.initializeOptions()
    this.organization = this.commonService.organization
  }

  ngOnInit() {
    this.commonService.clicked = false
    this.role = this.authService.role
    if (this.role == 1) {
      this.rolesList = ["0"]
    }
    if (this.role == 2) {
      this.rolesList = ["0", "1"]
    }

    this.defaultGroupValue = this.authService.groups?.[0]["name_id"]
    this.currentOrg = this.localStorageService.get("clickedRowNameId") as string

    this.addNewUserForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      middleName: [""],
      role: [this.role == "1" ? "0" : ""],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(320)],
      ],
      selectedGroup: [""],
      replacedGroup: [""],
    })

    this.addNewUserForm
      .get("group")
      ?.valueChanges.subscribe((selectedValue) => {
        this.addNewUserForm.get("selectedGroup")?.setValue(selectedValue)
      })
    this.searchCtrl.valueChanges.subscribe(() => {
      this.filterOptions()
    })

    if (this.authService.permissions?.includes("create:platform_users")) {
      if (this.currentOrg) {
        this.selectCtrl.setValue(this.currentOrg)
      } else {
        this.selectCtrl.setValue(this.defaultGroupValue)
      }
    } else if (
      this.authService.permissions?.includes("create:organization_users")
    ) {
      this.selectCtrl.disable()
      this.selectCtrl.setValue(this.defaultGroupValue)
    }
  }

  addNewUser() {
    this.commonService.userLoading = true
    const selectedGroupValue = this.selectCtrl.value
    // Extracting necessary fields from the form values
    const { email, firstName, lastName, middleName, role } =
      this.addNewUserForm.value

    if (this.authService.permissions?.includes("create:platform_users")) {
      if (this.addNewUserForm.get("replacedGroup")?.untouched) {
        const groupValue = Array.isArray(this.organization)
          ? this.organization.join(" ")
          : this.organization
        const payload = {
          email,
          firstName,
          group: selectedGroupValue, // Use the selected group value here
          lastName,
          middleName: middleName || "",
          role,
        }
        this.http
          .post("create_user/", payload)
          .pipe(
            catchError((err: any) => {
              console.log("error")

              throw "error in source. Details: " + err
            }),
          )
          .subscribe((response: any) => {
            if (response) {
              this.commonService.userLoading = false
              this.commonService.announceUserUpdate()
              this.commonService.noUsers = false
            }
            this.dialog.closeAll()
            const message = `Account created. Invitation email sent to ${email}`
            this.snackbar.openFromComponent(CustomSnackbarUserComponent, {
              data: { text: message },
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: [
                "my-custom-snackbar-error",
                "mat-toolbar",
                "mat-primary",
              ],
            })
          })
      } else {
        // console.log(this.organization)

        const payload = {
          email,
          firstName,
          group: selectedGroupValue, // Use the selected group value here
          lastName,
          middleName: middleName || "",
          role,
        }

        this.http
          .post("create_user/", payload)
          .pipe(
            catchError((err: any) => {
              console.log("error")
              throw "error in source. Details: " + err
            }),
          )
          .subscribe((response: any) => {
            if (response) {
              this.commonService.userLoading = false
              this.commonService.announceUserUpdate()
            }
            this.dialog.closeAll()
            const message = `Account created. Invitation email sent to ${email}`
            this.snackbar.openFromComponent(CustomSnackbarUserComponent, {
              data: { text: message },
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: [
                "my-custom-snackbar-error",
                "mat-toolbar",
                "mat-primary",
              ],
            })
          })
      }
    } else {
      const payload = {
        email,
        firstName,
        group: this.defaultGroupValue,
        lastName,
        middleName: middleName || "",
        role,
      }
      this.http
        .post("create_user/", payload)
        .pipe(
          catchError((err: any) => {
            console.log("error")
            throw "error in source. Details: " + err
          }),
        )
        .subscribe((response: any) => {
          if (response) {
            this.commonService.announceUserUpdate()
          }
          this.dialog.closeAll()
          const message = `Account created. Invitation email sent to ${email}`
          this.snackbar.openFromComponent(CustomSnackbarUserComponent, {
            data: { text: message },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
          })
        })
    }
    this.commonService.clicked = true
  }

  initializeOptions() {
    const defaultSelectedGroup = this.commonService.organization

    this.filterOptions()
    this.selectCtrl.setValue(defaultSelectedGroup)
  }

  addOption(value: string) {
    this.options.push(value)
    this.searchCtrl.setValue("")
    this.filteredOptions = [...this.options]

    // Re-initialize the form control
    this.selectCtrl = new FormControl(value)
  }

  clearSearch() {
    this.searchCtrl.setValue("")
  }

  filterOptions() {
    const filterValue = this.searchCtrl.value
      ? this.searchCtrl.value.toLowerCase()
      : ""
    this.filteredOptions = this.options.filter((option: any) =>
      option.toLowerCase().includes(filterValue),
    )
  }
}
