import { HttpClient, HttpHeaders } from "@angular/common/http"
import { EventEmitter, Injectable, signal } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService as AngularAuthService, User } from "@auth0/auth0-angular"
import jwtDecode from "jwt-decode"
import {
  Subject,
  Subscription,
  filter,
  map,
  Observable,
  tap,
  catchError,
  of,
  BehaviorSubject,
  switchMap,
  firstValueFrom,
} from "rxjs"
import { environment } from "../../../environments/environment"
import { db } from "../../../db"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CustomSnackbarJobComponent } from "src/app/features/feature/components/custom-snackbar-job/custom-snackbar-job.component"
import { WorkerserviceService } from "./workerservice.service"
import { MatDialog } from "@angular/material/dialog"
import { CommonService } from "./commonservice.service"
import { LocalStorageService } from "ngx-localstorage"
import { CustomSnackbarConfigErrComponent } from "src/app/features/feature/components/custom-snackbar-config-err/custom-snackbar-config-err.component"
import { error } from "console"

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void
}

export interface CognitoCallback {
  cognitoCallback(message: any, result: any): void

  handleMFAStep?(
    challengeName: string,
    challengeParameters: ChallengeParameters,
    callback: (confirmationCode: string) => any,
  ): void
}
export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string

  CODE_DELIVERY_DESTINATION: string
}
export class EventData {
  name: string
  value: any

  constructor(name: string, value: any) {
    this.name = name
    this.value = value
  }
}
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private subject$ = new Subject<EventData>()
  username: any
  role: any // Deprecated
  role_name: any
  pathForlder: any
  family_name: string = ""
  given_name: string = ""
  middle_name: string = ""
  first_name: string = ""
  last_name: string = ""
  email: string | undefined
  groups: Array<{ [key: string]: string }> | undefined
  organization_name: any
  firstName = signal("")
  lastName = signal("")
  organizationName = signal("")
  namesIsLoaded: boolean = false
  hasUser = new BehaviorSubject<boolean>(false)
  isFirstAuthenticated = new BehaviorSubject<boolean>(false)
  permissions: Array<string> | undefined
  isAuthenticated$: Observable<boolean>

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  userdata: any
  tokenData: any
  baseUrl: any
  document: any

  id: string | undefined

  constructor(
    private router: Router,
    private http: HttpClient,
    public snackbar: MatSnackBar,
    private httpClient: HttpClient,
    public workerService: WorkerserviceService,
    private dialog: MatDialog,
    public commonService: CommonService,
    private localStorageService: LocalStorageService,
    private angularAuthService: AngularAuthService,
  ) {
    this.baseUrl = environment.baseUrl
    this.firstName.set(this.first_name || "")
    this.lastName.set(this.last_name || "")
    this.isAuthenticated$ = this.angularAuthService.isAuthenticated$
  }

  on(eventName: string, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EventData) => e.name === eventName),
        map((e: EventData) => e["value"]),
      )
      .subscribe(action)
  }
  loginWithRedirect() {
    this.angularAuthService.loginWithRedirect({
      appState: { target: "/metadata" },
    })
  }

  isLoggedIn() {
    if (!this.angularAuthService.isAuthenticated$) {
      this.loginWithRedirect()
    }
    if (!this.hasUser.value) {
      this.isFirstAuthenticated
        .asObservable()
        .pipe(filter((value) => value == true))
        .subscribe(() => {
          this.getSingleUserInfo_unsafe().subscribe((user) => {
            this.userdata = user
            this.username = user["name"]
            this.family_name = user["family_name"]
            this.middle_name = user["middle_name"]
            this.given_name = user["given_name"]
            this.email = user["email"]
            this.role = user["custom:Role"]
            if (this.role == 0) {
              this.role_name = "User"
            } else if (this.role == 1) {
              this.role_name = "Admin"
            } else if (this.role == 2) {
              this.role_name = "Super Admin"
            }
            this.id = user["id"]
            this.groups = user["groups"]

            this.hasUser.next(true)
            this.namesIsLoaded = true
          })
        })
    }
    this.angularAuthService
      .getAccessTokenSilently()
      .subscribe((token: string) => {
        this.setToken(token)
      })
    return this.isFirstAuthenticated.asObservable()
  }
  setAuthentication(value: boolean) {
    this.isFirstAuthenticated.next(value)
  }

  onLogout(): void {
    this.angularAuthService.logout()
  }

  getToken(): string | null {
    return localStorage.getItem("authToken")
  }

  setToken(token: any) {
    localStorage.setItem("authToken", token)
    let token_decoded: any = jwtDecode(token as string)
    this.permissions = token_decoded.permissions

    this.isFirstAuthenticated.next(true)
  }
  getSingleUserInfo_unsafe(): Observable<any> {
    return this.http.get<any>("/get_user/")
  }

  getSingleUserInfo(): Observable<any> {
    return new Observable<any>((observer) => {
      this.waitForUser().then(() => {
        observer.next(this.getSingleUserInfo_unsafe())
        observer.complete()
      })
    })
  }

  async waitForUser() {
    if (this.hasUser.value) {
      return true
    }
    return await firstValueFrom(
      this.hasUser.pipe(filter((value) => value == true)),
    )
  }

  async loadNames() {
    this.http.get("/get_user/", { responseType: "text" }).subscribe({
      next: (res: string) => {
        if (res.startsWith("<!DOCTYPE html>") || res.includes("<html>")) {
          let snackBarRef = this.snackbar.openFromComponent(
            CustomSnackbarConfigErrComponent,
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: [
                "my-custom-snackbar-error",
                "mat-toolbar",
                "mat-primary",
              ],
            },
          )
          snackBarRef.afterDismissed().subscribe((res) => {
            if (res.dismissedByAction) {
            }
          })
          return
        }

        let jsonResponse
        try {
          jsonResponse = JSON.parse(res)
        } catch (e) {
          console.error("Error parsing JSON:", e)
          return
        }

        if (jsonResponse) {
          this.namesIsLoaded = true
          this.localStorageService.set("given_name", jsonResponse.given_name)
          this.localStorageService.set("family_name", jsonResponse.family_name)
          this.localStorageService.set(
            "organization_name",
            jsonResponse.groups[0]["name"],
          )
          this.first_name = this.localStorageService.get("given_name") as string
          this.last_name = this.localStorageService.get("family_name") as string
          this.organization_name = this.localStorageService.get(
            "organization_name",
          ) as string
          this.firstName.set(this.first_name)
          this.lastName.set(this.last_name)
          this.organizationName.set(this.organization_name)
        }
      },
      error: (error) => {
        console.error("HTTP error:", error)
      },
    })
  }
}
